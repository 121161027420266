<template>
    <Modal :title="title" v-model="show" class-name="vertical-center-modal"
           @on-cancel="cancel" @on-ok="save" :loading="loading">
        <Tree ref="tree" :data="permissionInfo" show-checkbox expand-node></Tree>
    </Modal>
</template>

<script>
    export default {
        name: "UpdatePermission",
        data(){
            return {
                title:"角色授权",
                show: false,
                loading: false,
                checkStrictly : false,
                permissionInfo:[]
            }
        },
        props: {
            updatePermissionShow:{
                type: Boolean,
                default: false,
            },
            updatePermissionRoleInfo:{}
        },
        methods:{
            cancel() {
                this.$parent.updatePermissionShow = false;
                this.permissionInfo = [];
                this.$parent.updatePermissionRoleInfo = {};
            },
            save(){
                this.loading = true
                let selectedMenus = this.$refs.tree.getCheckedAndIndeterminateNodes();
                let pUuids = [];
                selectedMenus.forEach((value) => {
                    pUuids.push(value.uuid);
                })

                let data = {
                    uuid: this.updatePermissionRoleInfo.uuid,
                    permissionUuids : pUuids
                }

                this.post("/permission/role/permission/update", data).then(() => {
                    this.$Message.info({
                        content: '保存成功'
                    });
                    this.loading = false;
                    this.cancel()
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        watch:{
            updatePermissionShow(){
                this.show = this.updatePermissionShow;
            },
            show(){
                if(!this.show){
                    this.$parent.updatePermissionShow = this.show;
                } else {
                    this.post("/permission/menu/list", {}).then(value => {
                        let data = value.data.data
                        let mainMenu = [];
                        data.forEach((o) => {
                            if(!o.parentUuid){
                                o['children'] = [];
                                o['expand'] = true,
                                o['title'] = o.permissionName;
                                mainMenu.push(o)
                                loop (o, data)
                            }
                        })

                        function loop (menu, menus) {
                            menus.forEach((m) => {
                                if(m.parentUuid == menu.uuid){
                                    if(!menu.children){
                                        menu['children'] = []
                                        menu['expand'] = true
                                    }
                                    m['title'] = m.permissionName;
                                    menu.children.push(m)
                                    loop(m, menus)
                                }
                            })

                            if(menu.children){
                                menu.children = menu.children.sort((a, b) => {
                                    return a.sortby - b.sortby
                                })
                            }
                        }

                        // this.permissionInfo = mainMenu;

                        this.post("/permission/role/permission/list", {uuid: this.updatePermissionRoleInfo.uuid}).then(value => {
                            let puuidMap = [];
                            value.data.data.forEach(p => {
                                puuidMap[p.permissionUuid] = p;
                            })
                            mainMenu.forEach(value => {
                                loopSelect(value)
                            })
                            function loopSelect(value){
                                if(puuidMap[value.uuid] && (!value.children || value.children.length == 0)){
                                    value["checked"] = true;
                                } else if (value.children && value.children.length > 0) {
                                    value.children.forEach(value1 => {
                                        loopSelect(value1)
                                    })
                                }
                            }

                            this.permissionInfo = mainMenu;
                            this.$forceUpdate()
                        });
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
