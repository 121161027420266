<template>
    <Modal :title="title" v-model="show" class-name="vertical-center-modal"
           @on-cancel="cancel" @on-ok="save" :loading="loading">
        <Form :model="formItem" :label-width="80">
            <FormItem label="角色名称">
                <Input v-model="formItem.roleName" placeholder="请输入角色名称"></Input>
            </FormItem>
            <FormItem label="角色编码">
                <Input v-model="formItem.roleCode" placeholder="请输入角色编码"></Input>
            </FormItem>
            <FormItem label="状态">
                <RadioGroup v-model="formItem.status">
                    <Radio label="1">启用</Radio>
                    <Radio label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="备注">
                <Input v-model="formItem.remark" placeholder="请输入备注"></Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
    export default {
        name: "AddRole",
        data(){
            return {
                title:"新增角色",
                formItem:{
                    roleName: "",
                    roleCode: "",
                    status: "1",
                    remark: ""
                },
                show: false,
                loading: false
            }
        },
        props: {
            addRoleShow:{
                type: Boolean,
                default: true,
            }
        },
        methods:{
            cancel() {
                this.$parent.addRoleShow = false
            },
            save(){
                this.loading = true
                this.post("/permission/role/add", this.formItem).then(() => {
                    this.$Message.info({
                        content: '保存成功'
                    });
                    this.$parent.loadData();
                    this.loading = false;
                    this.cancel()
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        watch:{
            addRoleShow(){
                this.show = this.addRoleShow;
            },
            show(){
                this.formItem = {
                    roleName: "",
                    roleCode: "",
                    status: "1",
                    remark: ""
                };
                this.$parent.addRoleShow = this.show;
            }
        }
    }
</script>

<style scoped>
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ivu-modal{
        top: 0;
    }
</style>