<template>
    <div>
        <Form :model="search" :label-width="80">
            <Row>
                <Col span="4">
                    <FormItem label="角色名称">
                        <Input v-model="search.roleName" placeholder="请输入角色名称"></Input>
                    </FormItem>
                </Col>
                <Col span="4">
                    <FormItem label="角色编码">
                        <Input v-model="search.roleCode" placeholder="请输入角色编码"></Input>
                    </FormItem>
                </Col>
                <Col span="14">
                    <Button type="primary" class="margin_left_10" @click.native="loadData">查询</Button>
                    <Button type="primary" class="margin_left_10" @click.native="showAddRole">新增</Button>
                </Col>
            </Row>
        </Form>
        <Table :columns="columns" :data="data">
            <template slot-scope="{ row }" slot="status">
                {{ row.isDefault == 1 ? "内置角色" : row.status == 1 ? "启用" : "禁用" }}
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button size="small" class="margin_right_10" v-if="row.isDefault == 0" @click.native="updateStatus(row)" :loading="updateStatusLoading">{{ row.status == 0 ? "启用" : "禁用" }}</Button>
                <Button size="small" type="primary" @click.native="updateRole(row)" class="margin_right_10" v-if="row.isDefault == 0">修改</Button>
                <Button size="small" type="primary" @click.native="showUpdatePermission(row)" class="margin_right_10">授权</Button>
                <Button size="small" type="error" @click.native="delRole(row.uuid)" class="margin_right_10" v-if="row.isDefault == 0">删除</Button>
            </template>
        </Table>
        <Page class="margin_10" v-if="page.pages > 1" :total="page.total" :current="page.pageNum" @on-change="changePage" show-elevator show-sizer/>
        <AddRole :addRoleShow="addRoleShow" ></AddRole>
        <UpdateRole :updateRoleShow="updateRoleShow" :roleInfo="roleInfo"></UpdateRole>
        <UpdatePermission :updatePermissionShow="updatePermissionShow" :updatePermissionRoleInfo="updatePermissionRoleInfo"></UpdatePermission>
    </div>
</template>
<script>
    import AddRole from '/src/framework/role/AddRole.vue';
    import UpdateRole from '/src/framework/role/UpdateRole.vue';
    import UpdatePermission from '/src/framework/role/UpdatePermission.vue';
    export default {
        name: 'Role',
        components:{
            AddRole,
            UpdateRole,
            UpdatePermission
        },
        data () {
            return {
                addRoleShow: false,
                updateRoleShow: false,
                roleInfo:{},
                updatePermissionShow: false,
                updatePermissionRoleInfo: {},
                updateStatusLoading: false,
                search: {
                    roleName: "",
                    roleCode: ""
                },
                page:{
                    pageNum: 1,
                    pageSize: 10,
                    total: 0,
                    pages: 1
                },
                columns:[
                    {
                        title: '角色名称',
                        key: 'roleName'
                    },
                    {
                        title: '角色编码',
                        key: 'roleCode'
                    },
                    {
                        title: '状态',
                        slot: 'status'
                    },
                    {
                        title: '备注',
                        key: 'remark'
                    },
                    {
                        title: '操作',
                        slot: 'action'
                    }
                ],
                data:[]
            }
        },
        created() {
            this.loadData();
        },
        methods : {
            loadData: function () {
                let page = {
                    pageNum : this.page.pageNum - 1,
                    pageSize : this.page.pageSize
                }
                let _search = Object.assign({}, this.search, page);
                this.post("/permission/role/page", _search).then(value => {
                    this.data = value.data.data.data
                    this.page = {
                        total: value.data.data.total,
                        pageSize: value.data.data.pageSize,
                        pageNum: value.data.data.pageNum,
                        pages: value.data.data.pages
                    }
                }).catch(reason => {
                    console.log(reason);
                })
            },
            changePage(pageNum){
              this.page.pageNum = pageNum;
              this.loadData();
            },
            showAddRole(){
                this.addRoleShow = true;
            },
            delRole(uuid){
                this.$confirm('此操作将删除该角色, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.post("/permission/role/del", {uuid: uuid}).then(() => {
                        this.loadData();
                    }).catch(reason => {
                        console.log(reason);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除操作'
                    });
                });
            },
            updateRole(data){
                this.updateRoleShow = true;
                this.roleInfo = data;
            },
            updateStatus(data){
                this.updateStatusLoading = true;
                let status = data.status == 1 ? 0 : 1;
                this.post("/permission/role/status/update", {uuid: data.uuid, status: status}).then(() => {
                    this.loadData();
                    this.$Message.info({
                        content: status == 1? '启用成功' : "禁用成功"
                    });
                    this.updateStatusLoading = false;
                }).catch(reason => {
                    console.log(reason);
                    this.$Message.info({
                        content: status == 1? '启用失败' : "禁用失败"
                    });
                    this.updateStatusLoading = false;
                })
            },
            showUpdatePermission(data){
                this.updatePermissionShow = true;
                this.updatePermissionRoleInfo = data;
            }
        }
    }
</script>

<style>
</style>
